<style lang="scss" scoped>
  h4 {
    font-family: "Circular Std Medium";
    color: $brandPurple;

    &.disabled {
      opacity: 0.5;
    }
  }
</style>

<template>
  <v-container class="h-100 d-flex flex-column">
    <v-form class="h-100 d-flex flex-column" validate-on="submit lazy" @submit.prevent="onSubmit">
      <div class="d-flex align-center flex-column flex-grow-1">
        <h2 class="text-center mb-4">
          {{ heading }}
        </h2>
        <p class="text-center text-balance">
          {{ message }}
        </p>
        <Transition name="fade" mode="out-in">
          <div v-if="!sent" class="d-flex justify-center w-100 my-12">
            <Phone
              ref="phoneInput"
              v-model="phone"
              label="Your phone number"
              validate-on="submit lazy"
            />
          </div>
          <div v-else class="d-flex flex-column align-center w-100 my-12">
            <div class="mb-12">
              <h4 :class="{ disabled }" class="d-flex justify-center">
                <v-icon icon="mdi-check-circle-outline" />&nbsp;Verification Code Sent
              </h4>
            </div>
            <Code
              ref="codeInput"
              v-model="code"
              validate-on="submit lazy"
            />
          </div>
        </Transition>
      </div>
      <Transition name="fade" mode="out-in">
        <div v-if="!sent" class="d-flex flex-column align-center flex-grow-0">
          <Button class="mb-6" :disabled="disabled" type="submit">
            {{ label }}
          </Button>
          <slot />
        </div>
        <div v-else class="d-flex flex-column align-center flex-grow-0">
          <Button class="mb-6" :disabled="disabled" type="submit">
            {{ label }}
          </Button>
          <div class="mb-6">
            Didn't receive a code? <a @click.prevent="onResend">Resend</a>
          </div>
        </div>
      </Transition>
    </v-form>
  </v-container>
</template>

<script setup>
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { validatePhone } from '@/utils/validators';

const props = defineProps({
  sent: {
    type: Boolean,
    default: false,
  },
  phone: {
    type: String,
    default: null,
  },
  code: {
    type: String,
    default: null,
  },
  heading: {
    type: String,
    default: '',
  },
  message: {
    type: String,
    default: '',
  },
  label: {
    type: String,
    default: 'Continue',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const phoneInput = ref();
const codeInput = ref();
const phone = ref(props.phone);
const code = ref(props.code);

const emit = defineEmits(['update:phone', 'update:code', 'send', 'resend', 'submit']);

watch(() => props.code, function (newValue) {
  code.value = newValue;
});

const isPhoneValid = computed(() => {
  return phone.value && validatePhone(phone.value);
});

const isCodeValid = computed(() => {
  return code.value && /[0-9]{6}/.test(code.value);
});

onMounted(() => {
  phoneInput.value.focus();
});

watch(() => props.sent, function () {
  setTimeout(() => {
    if (codeInput.value) {
      codeInput.value.focus();
    }
  }, 250);
});

watch(phone, function (newValue) {
  const parsed = parsePhoneNumberFromString(newValue, 'US');
  if (parsed) {
    emit('update:phone', parsed.nationalNumber);
  }
});

watch(code, function (newValue) {
  emit('update:code', newValue);
});

function onResend () {
  emit('resend');
}

function onSubmit () {
  if (!props.sent) {
    if (isPhoneValid.value) {
      emit('send');
    }
  }

  if (props.sent) {
    if (isCodeValid.value) {
      emit('submit');
    }
  }
}

</script>
